

























































































import type { FlexibleVideo, LabelValue, Link, Picture } from '@/inc/types'

import {
  defineComponent,
  PropType,
  ref,
  onMounted,
  // onUnmounted,
} from '@vue/composition-api'
import { gsap } from 'gsap'

import { push, GtmLayer } from '@/inc/utils'

import UiPicture from '@/components/ui/Picture.vue'

interface Expert {
  breadcrumb: LabelValue<string>[]
  ctas: {
    link: Link
    title: string
    type: string
  }[]
  htmltext: string
  header: {
    title: string
  }
  gallery: Picture[]
  title: string
  url: string
  video: FlexibleVideo
}

interface FlexibleExperts {
  title: string
  subtitle: string
  experts: Expert[]
  htmltext: string
}

export default defineComponent({
  name: 'FlexibleExperts',
  components: { UiPicture },
  props: {
    content: {
      type: Object as PropType<FlexibleExperts>,
      required: true,
    },
  },
  setup() {
    const popup = ref()
    const popupContent = ref<Record<string, unknown> | null>(null)
    const total = ref(0)
    const current = ref(0)
    const canSlide = ref(true)
    const index = ref(0)
    const slider = ref()
    const items = ref()
    const text = ref()
    const windowWidth = ref()
    const isSliding = ref(false)
    let isMobile = true

    const onClick = (expert: Expert) => {
      openPopup(expert)

      pushDatalayer(expert)
    }

    const pushDatalayer = (expert: Expert) => {
      const layer: GtmLayer = {
        event: 'job_testimony_click',
        jobTestimonialValue: expert.url,
      }
      push(layer)
    }

    const closePopup = () => (popupContent.value = null)

    const openPopup = expert => {
      popupContent.value = expert
    }

    // Slider Related
    onMounted(() => {
      const { innerWidth } = window

      items.value.splice(2, 0, text.value)

      if (innerWidth > 1440) {
        windowWidth.value = 1440
      } else {
        windowWidth.value = innerWidth
      }
    })

    const onResize = (width: number) => {
      const newIsMobile = width <= 1024

      if (width > 1440) {
        windowWidth.value = 1440
      } else {
        windowWidth.value = width
      }

      if (isMobile !== newIsMobile) {
        isMobile = newIsMobile
      }

      // Reset slider position
      slide(0, false)
    }

    const updateProgress = () => {
      if (windowWidth.value <= 500) {
        total.value = 5
      } else if (windowWidth.value <= 1024) {
        total.value = 3
      } else {
        total.value = 2
      }

      if (index.value >= total.value) {
        canSlide.value = false
      } else {
        canSlide.value = true
      }
    }

    const prev = () => {
      // eslint-disable-next-line no-plusplus
      index.value--
      slide(windowWidth.value, false)
    }

    const next = () => {
      // eslint-disable-next-line no-plusplus
      index.value++
      slide(windowWidth.value, true)
    }

    const slide = (width: number, isPrev: boolean) => {
      isSliding.value = true

      const tl = gsap.timeline({
        onComplete: () => {
          isSliding.value = false
        },
      })
      tl.to(items.value, {
        x: index.value * (-width / 1.5),
        duration: 1,
        stagger: isPrev ? 0.01 : -0.01,
        ease: 'Power4.out',
      })

      updateProgress()
    }

    return {
      onClick,
      popup,
      popupContent,
      closePopup,
      total,
      current,
      canSlide,
      index,
      updateProgress,
      slider,
      windowWidth,
      prev,
      next,
      isSliding,
      onResize,
      items,
      text,
    }
  },
})
